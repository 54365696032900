<div class="surface-0 flex">

    <div id="home" class="overflow-hidden" style="width: 100%">
        <p-menubar [model]="menuItems">
            <ng-template pTemplate="start">
                <a href="">
                    <img src="assets/layout/images/logo.png"
                         alt="Sakai Logo" height="40" class="mr-0 lg:mr-2">
                </a>
            </ng-template>
            <ng-template pTemplate="end">
                <div class="flex align-items-center">
                    <language-selector></language-selector>
                </div>
            </ng-template>
        </p-menubar>
        <p-toolbar styleClass="justify-content-end pb-3" >
            <p-button *ngIf="!authService.userLogged()"
                      [routerLink]="'user-login'">{{'auth.login' | translate}}</p-button>
            <p-button *ngIf="!authService.userLogged()"
                      [routerLink]="'registration'">{{'auth.registration' | translate}}</p-button>
            <div class="flex gap-1 text-white" *ngIf="authService.userLogged()">
                <div class="flex-2">
                    <button class="text-white" disabled pButton
                            icon="pi pi-user">{{authService.getLoggedUserUsername()}}</button>
                </div>
                <div class="flex-2">
                    <button class="w-full" pButton
                            [routerLink]="'my-profile'">{{'auth.my-profile' | translate}}</button>
                </div>
                <div class="flex-2">

                    <button class="w-full" pButton
                            (click)="authService.logOut('')">{{'auth.logout' | translate}}</button>
                </div>

            </div>
        </p-toolbar>
        <div class="landing-wrapper" style="width: 100%; height: 100%">
            <router-outlet></router-outlet>

            <public-footer></public-footer>
        </div>
    </div>
</div>
